<template>
 <v-container  v-if="!allcategory">
    <v-row class="loaderHeight" >
      <v-col cols="2" align-self="center" offset="5" >
        <!-- <v-progress-circular
          size="40"
          width="3"
          class="mx-auto"
          color="black"
          indeterminate
        ></v-progress-circular> -->
        <v-progress-linear
          max-width="150"
          color="black"
          height="6"
          indeterminate
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="mt-10" >
    <!-- <h2 class="text-center font-bold">Products</h2> -->
    <!-- <v-divider class="mt-5"></v-divider> -->
    <v-row class="d-flex justify-b mt-3">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        v-for="(product, index) in allcategory"
        :key="index"
      >
        <v-lazy loading transition="fade-transition">
          <v-card
            :to="{
              name: 'Products',
              params: { id: product.name },
            }"
            :loading="loading"
            class="my-3 py-2"
            max-width="420"
            elevation="0"
          >
            <template slot="progress">
              <v-progress-linear
                color="black"
                height="5"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              contain
              class="mb-5 pa-0  align-end text-center"
              lazy-src="/img/lazy.jpg"
              :src="product.imgSrc"
            >
            <v-btn  class="px-8 py-9 mb-16 rounded-0" style="opacity:0.75">
                <strong>{{product.name}}</strong>
              </v-btn>
            </v-img>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "categories",
  data() {
    return {
      allcategory: [
        { name: "Handtufted Rugs", imgSrc: "/img/ramsha_handtufted.jpg", assignBox:'Ramsha480'},
        { name: "Novara Rugs", imgSrc: "/img/ramsha_novara.jpg",assignBox:'Novara' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
